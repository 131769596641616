import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import Story from './components/story';
import { Link } from '../../components';

export default function Lux() {
  const { solutionImages } = useStaticQuery(assetsQuery);

  const solutionImage = solutionImages.edges.find(
    ({ node }) => node.name === 'solution_lux@2x',
  );
  /* eslint-disable max-len */
  const props = {
    name: 'Lux',
    title:
      'How LUX Rewards created a luxury rewards brand and increased average transaction value by 3x',
    seoTitle:
      'How LUX Rewards created a luxury rewards brand and increased average transaction value by 3x',
    subtitle: 'With James Courtney, Founder and CEO of LUX Rewards',
    heroImgAlt: 'Screenshots of LUX Rewards’ mobile app',
    keyFigures: {
      Website: <Link to="https://www.luxrewards.co.uk/">luxrewards.co.uk</Link>,
      Region: 'UK',
      Industry: 'Hospitality',
      'Products used': 'Transactions API',
    },
    companyElement: (
      <>
        <p>
          Coffee, lunch, manicures – the chances are that most of us have a
          loyalty card for at least one everyday item. But what about high-end
          brands? Why couldn’t a luxury bar or restaurant reward its customers
          too? There was a gap in the market and James Courtney, CEO and Founder
          of LUX Rewards, knew it.
        </p>
        <p>
          <Link to="https://www.luxrewards.co.uk/">LUX Rewards</Link> launched
          in 2016 - a loyalty platform for high-end spend. Luxury restaurants
          and bars are able to reward customers with equally indulgent perks.
          Customers earn points whenever they spend at participating restaurants
          and bars and once they’ve racked up enough points, they can choose
          from rewards like spa days, wine tasting tours or charity donations.
        </p>
      </>
    ),
    challengeElement: (
      <div>
        <p>
          James saw the primary challenge to earning users was overcoming the
          inconvenient customer journey that the LUX Rewards app currently
          supported. It was filled with unnecessary steps, creating friction and
          enrollment drop-off.
        </p>
        <p>
          “When we first built LUX Rewards, the customer journey through the app
          was very sticky” James explained.
        </p>
        <p>
          Customers would need to download the app, enter their bill amount at a
          restaurant, generate and present a unique code to staff, who then
          entered the code manually into a system to register the reward. For
          customers, it was inconvenient. For participating merchant partners,
          they were also forced to re-train staff to use the system too. There
          was another problem. Due to technical limitations, James and his team
          were only able to capture about 50% of transactions.
        </p>
        <p>
          James had considered alternatives and workarounds. Photograph
          receipts? Sadly, that didn’t work either. “We were only able to
          capture about 10% of transactions using receipt scanning” explains
          James. “It meant even less visibility over customer behaviour and was
          actively deterring some from using the app”.
        </p>
      </div>
    ),
    highlight:
      '“Fidel API makes earning rewards so much easier. Both our restaurant partners and users love it. Card-linking has helped us increase our retention rate, improve our proposition to restaurants and drive higher lifetime value for them.”',
    highlightImgAlt: 'Screenshot of LUX Rewards’ mobile app',
    highlightImgClassName: 'padded-right',
    quoteAuthor: {
      name: 'James Courtney',
      description: 'Founder and CEO of LUX Rewards',
      avatar: 'james',
    },
    solutionElement: (
      <div>
        <p>
          James needed a sleek, engaging way for customers to earn rewards, that
          could also provide improved visibility of valuable transaction data
          back to restaurants and bars. A product built on top of the payment
          cards themselves seemed like the best solution.
        </p>
        <p>
          Not only would users instantly get rewarded at checkout, but LUX
          Rewards would also be able to capture 100% of linked-card
          transactions.
        </p>
        <p>
          “Card-linking works so well for LUX Rewards because it’s discreet”
          says James. “We cater to a specific segment of people who dine at
          high-end restaurants and bars. The only way those people will sign up
          is if the customer experience is effortless”.
        </p>
        <p>
          It was this understanding that led James to consider Fidel API’s
          payment APIs as a viable technology to build the new and improved LUX
          Rewards app on top of.
        </p>
        <p>
          Users could simply link their chosen credit or debit card, and
          automatically earn points. It would be a seamless experience without
          any additional steps at checkout, would require zero additional staff
          training and LUX Rewards wouldn’t have to rely on expensive and
          unintuitive hardware. Finally, LUX Rewards would have secure and clear
          visibility of card-linked transactions – James knew that customer
          retention and lifetime value (LTV) could be drastically improved as a
          result, too.
        </p>
        <GatsbyImage
          image={solutionImage.node.childImageSharp.gatsbyImageData}
          alt="Different screens of the Lux app"
          style={{ margin: '60px 0' }}
        />

        <h2 className="text-left">The Check-in</h2>
        <p>
          The integration process between Fidel API and LUX was fast and easy,
          thanks to the simplicity of connecting to API technology. The improved
          LUX Rewards app was ready to go to market and went live in November
          2019.
        </p>
        <p>
          It’s safe to say, James was pleased with the results. 85% of active
          users have linked their cards since the new version of the app since
          launch. What’s more, those users are also spending more when they
          visit participating merchants. From January to March 2019, LUX Rewards
          increased its average customer transaction value (ATV) three-fold.
        </p>
        <p>
          “Fidel API makes earning rewards so much easier” says James. “Both our
          restaurant partners and users love it. Card-linking has helped us
          increase our retention rate, improve our proposition to restaurants
          and drive higher lifetime value for them”.
        </p>
        <p>
          So what’s next for James and the LUX team? Naturally, they’re
          continuing to strive for nothing short of excellence when it comes to
          their user experience, and have plans to roll out to more cities
          across the UK. Their target is to surpass 1,000 restaurant partners
          and 100,000 users over the coming year.
        </p>
      </div>
    ),
  };
  /* eslint-enable */

  return <Story {...props} />;
}

const assetsQuery = graphql`
  {
    solutionImages: allFile(
      filter: { relativePath: { regex: "/stories/solution/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
